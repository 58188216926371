import { Box, Button } from "@mui/material";
import HeaderComponent from "../components/HeaderComponent";

const HomeLayout = () => {

    return (
        <Box sx={{
            height: "100vh",
            width: "100vw",
        }}>
            <HeaderComponent />
            <Box sx={{
                height: "calc(100vh - 5rem)",
                width: "100vw",
                display:"flex",
                flexDirection:{xs:"column", md:"row"},
                alignItems:"center",
                justifyContent:"center"
            }}>

                <Box component="img"
                    src="https://www.geoconsurveys.in/web_assets/uploads/6579a753d52821a34551a81db10909c5.jpg"
                    alt="Logo"
                    sx={{
                        width: {xs:"250px", md:"450px"},
                        height:{xs:"250px", md:"450px"},
                        m: 2
                    }}
                />

                <Box component="img"
                    src="https://www.geoconsurveys.in/web_assets/uploads/a6a4209a4823adc6e86fa58f1d548520.jpg"
                    alt="Logo"
                    sx={{
                        width: {xs:"250px", md:"450px"},
                        height:{xs:"250px", md:"450px"},
                        m: 2
                    }}
                />
                <Box component="img"
                    src="https://www.geoconsurveys.in/web_assets/uploads/c680b8b75089382311c8276151a2a852.jpg"
                    alt="Logo"
                    sx={{
                        width: {xs:"250px", md:"450px"},
                        height:{xs:"250px", md:"450px"},
                        m: 2
                    }}
                />
            </Box>

        </Box>
    )
}
export default HomeLayout;