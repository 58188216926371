import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiCloudDownload } from "react-icons/bi";

const InvoiceModal = ({
  showModal,
  closeModal,
  info,
  currency,
  total,
  items,
  taxAmount,
  discountAmount,
  subTotal,
  compName,
  compPan,
  accNum,
  phNum,
  ifscCode,
  bankName,
  emailID
}) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const totalBillAmount = items.reduce((acc, item) => {
    return (
      acc +
      item.perUnitPrice * item.quantity +
      (item.perUnitPrice * 9) / 100 +
      (item.perUnitPrice * 9) / 100
    );
  }, 0);

  return (
    <div>
      <Modal show={showModal} onHide={closeModal} size="lg" centered>
        <div
          ref={componentRef}
          id="invoiceCapture"
          style={{
            backgroundImage: `url("https://res.cloudinary.com/dmlhm8dwi/image/upload/v1724333877/pain_white_zsaovb.png")`,
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "30px",
              backgroundImage: `url("https://res.cloudinary.com/dmlhm8dwi/image/upload/v1724333877/pain_white_zsaovb.png")`,
              backgroundSize: "cover",
            }}
          >
            <img
              src={info.companyLogo}
              style={{
                width: "400px",
                textAlign: "center",
              }}
            />
            <p
              style={{
                alignSelf: "flex-start",
              }}
            >
              Date: {new Date(info.dateOfIssue).toLocaleDateString("en-GB")}
            </p>
            <div
              style={{
                height: "861px",
                padding: "0px",
                margin: "0px",
                width: "100%",
                // border: "1px solid RED",
              }}
            >
              <div
                style={{
                  border: "2px solid black",
                  width: "100%",
                }}
              >
                <h1
                  style={{
                    textAlign: "center",
                  }}
                >
                  INVOICE
                </h1>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        borderTop: "2px solid black",
                        borderRight: "1px solid black",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        maxWidth: "50%",
                        wordWrap: "break-word",
                      }}
                    >
                      <p
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "10px",
                          textAlign: "start",
                          margin: "0px",
                        }}
                      >
                        Invoice No: {info.invoiceNumber}
                      </p>
                    </div>
                    <div
                      style={{
                        borderTop: "2px solid black",
                        borderLeft: "1px solid black",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        maxWidth: "50%",
                        wordWrap: "break-word",
                      }}
                    >
                      <p
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "start",
                          padding: "10px",
                          margin: "0px",
                        }}
                      >
                        Service Type: {info.serviceType}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <div
                      style={{
                        borderRight: "1px solid black",
                        width: "100%",
                        minHeight: "100%",
                        padding: "5px",
                        maxWidth: "50%",
                        wordWrap: "break-word",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        From
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          {info.billFrom}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        Address{" "}
                        <span style={{}}>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            :
                          </span>{" "}
                          {info.billFromAddress}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        GSTIN{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            marginLeft: "11px",
                          }}
                        >
                          : {info.gstNo}
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        borderLeft: "1px solid black",
                        width: "100%",
                        minHeight: "100%",
                        padding: "5px",
                        maxWidth: "50%",
                        wordWrap: "break-word", // Add this line
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        To
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        Name
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            marginLeft: "15px",
                          }}
                        >
                          : {info.billTo}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        Concern Person{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          :
                        </span>{" "}
                        {info.billToMrOrMiss} {info.concernPerson}
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        Address{" "}
                        <span style={{}}>
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            :
                          </span>{" "}
                          {info.billToAddress}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        Phone{" "}
                        <span
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            :
                          </span>{" "}
                          {info.billToPhone}
                        </span>
                      </p>
                      <p>
                        GST No
                        <span style={{ marginLeft: "10px" }}>
                          : {info.billToGstNo}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRight: "1px solid black",
                        width: "100%",
                        minHeight: "100%",
                        padding: "5px",
                        maxWidth: "50%",
                        wordWrap: "break-word",
                        margin: "0px",
                      }}
                    >
                      <p style={{ margin: "0px" }}>
                        State
                        <span style={{ marginLeft: "20px" }}>
                          : {info.billFromState}
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        borderLeft: "1px solid black",
                        width: "100%",
                        minHeight: "100%",
                        padding: "5px",
                        maxWidth: "50%",
                        wordWrap: "break-word",
                        margin: "0px",
                      }}
                    >
                      <p style={{ margin: "0px" }}>
                        State
                        <span style={{ marginLeft: "20px" }}>
                          : {info.billToState}
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* Table */}
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                              width: "45px",
                              fontSize: "10px",
                              margin: "0px",
                            }}
                          >
                            S. No
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                              fontSize: "10px",
                              margin: "0px",
                            }}
                          >
                            Name of the work
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                              fontSize: "10px",
                              margin: "0px",
                            }}
                          >
                            Qty
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                              fontSize: "10px",
                              margin: "0px",
                            }}
                          >
                            Per One Unit
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                              fontSize: "10px",
                              margin: "0px",
                            }}
                          >
                            CGST 9 %
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                              fontSize: "10px",
                              margin: "0px",
                            }}
                          >
                            SGST 9 %
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                              fontSize: "10px",
                              margin: "0px",
                            }}
                          >
                            Tax Amount
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                              textAlign: "center",
                              fontSize: "10px",
                              margin: "0px",
                            }}
                          >
                            Total Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {item.name}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {item.quantity}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {item.perUnitPrice}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {(item.perUnitPrice * 9) / 100}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {(item.perUnitPrice * 9) / 100}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {(item.perUnitPrice * 9) / 100 +
                                (item.perUnitPrice * 9) / 100}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                padding: "8px",
                              }}
                            >
                              {(
                                item.perUnitPrice * item.quantity +
                                (item.perUnitPrice * 9) / 100 +
                                (item.perUnitPrice * 9) / 100
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      height: "160px",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        borderRight: "1px solid black",
                        padding: "10px",
                        width: "45%",
                      }}
                    >
                      <h6
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {compName}
                      </h6>
                      <h6
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        BANK: {bankName}{" "}
                      </h6>
                      <h6
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        AC: {accNum}
                      </h6>
                      <h6
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        IFSC: {ifscCode}
                      </h6>

                      <h6
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        PH:- {phNum}
                      </h6>
                      <h6
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Company Pan: {compPan}
                      </h6>
                    </div>
                    <div
                      style={{
                        height: "30 %",
                        borderRight: "1px solid black",
                        borderBottom: "2px solid black",
                        padding: "10px",
                        width: "30%",
                        textAlign: "center",
                        alignSelf: "flex-start",
                      }}
                    >
                      <h6
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Total Bill Amount {currency}
                      </h6>
                    </div>
                    <div
                      style={{
                        height: "px",
                        padding: "10px",
                        width: "25%",
                      }}
                    >
                      <h6
                        style={{
                          fontWeight: "bold",
                          textAlign: "end",
                          position: "relative",
                          top: "-48px",
                          right: "10px",
                          width: "185px",
                          height: "35px",
                          paddingRight: "10px",
                        }}
                      >
                        {totalBillAmount.toFixed(2)}
                      </h6>
                      <div
                        style={{
                          width: "207px",
                          border: "1px solid black",
                          position: "relative",
                          top: "-55px",
                          right: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // height: "100px", 
                    }}
                  >
                    <div
                      style={{
                        width: "45%",
                        borderRight: "1px solid black",
                        padding: "10px",
                        height: "60px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "55%",
                        padding: "10px",
                        height: "100%",
                        position: "relative",
                        top: "-20px",
                        left: "100px",
                        // border: "1px solid RED",
                      }}
                    >
                      {compName} <br />
                      Signature Of The Contracting Agency
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer */}
            <hr
              style={{
                width: "100%",
                border: "2px solid black",
              }}
            />
            <p
              style={{
                textAlign: "center",
                color: "black",
              }}
            >
              {/* 47-7-24, B-3, Seshu Apartments, 4th Lane, Beside Gruhapriya
              sweets, DwarakaNagar, Visakhapatnam, AP – 530016. */}
              {info.billFromAddress}
              <br />{" "}
              <span style={{ color: "#576B95" }}>
                Mob: +91 {phNum} E-mail :
                {emailID}
              </span>
                 {/* www.invtechnologies.in */}
            </p>
          </div>
        </div>
        <div className="pb-4 px-4">
          <Row>
            <Col md={6}></Col>
            <Col md={6}>
              <Button
                variant="outline-primary"
                className="d-block w-100 mt-3 mt-md-0"
                onClick={handlePrint}
              >
                <BiCloudDownload
                  style={{ width: "16px", height: "16px", marginTop: "-3px" }}
                  className="me-2"
                />
                Download Copy
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default InvoiceModal;
